<template>
  <!-- [TODO_C3] -->
  <div>
    <a-alert message="Chart is disabled" type="info" show-icon>
      <template #description
        >The "vue-c3" plugin has not yet migrated to Vue3.
        <a
          href="https://github.com/chryb/vue-c3"
          target="_blank"
          rel="noopener noreferrer"
          class="vb__utils__link"
          >Visit github repository for details</a
        ></template
      >
    </a-alert>
  </div>
</template>
